export const environment = {
  production: true,
  log: true,
  application: {
    name: 'storefront.web',
    ssrEnabled: true,
    defaultStoreDomain: 'rexa.store',
    localServerPrivateIPs: '10.0.20.82',
    localServerUrls: 'localhost:4200,localhost:4000,localhost:57849',
  },
  oAuthConfig: {
    issuer: 'https://rexacont.azurewebsites.net/',
    clientId: 'Whyzr_App',
    scope: 'offline_access Whyzr',
  },
  apis: {
    default: {
      url: 'https://rexa.azurewebsites.net',
      rootNamespace: 'Whyzr',
    },
  },
  firebase: {
    apiKey: 'AIzaSyAVcD2nzPU4Nf-t7sP5vSPgN8LVwkF6D8Q',
    authDomain: 'whyzr-473a9.firebaseapp.com',
    databaseURL: 'https://whyzr-473a9.firebaseio.com',
    projectId: 'whyzr-473a9',
    storageBucket: 'whyzr-473a9.appspot.com',
    messagingSenderId: '643878576133',
    appId: '1:643878576133:web:1ca9edf4d4bd750f39bc6c',
    measurementId: 'G-3V9Y6YNVC2',
  },
  storeId: '96e47781-1419-fba7-5fee-3a07df0d45be',
  googleAPI: 'AIzaSyAvs-1XAaDqBDF64PFb7PiD9JHJFHxHI3w',
  ignoredefaultStoreConfig: false,
  provider: {
    name: 'EMENU',
    nameAr: 'منصة إيمينيو',
    logo: 'https://emenu.tech/wp-content/uploads/2021/09/%D8%A8%D8%AF%D9%88%D9%86-%D8%AE%D9%84%D9%81%D9%8A%D8%A9-2-1.png',
    website: 'https://emenu.tech',
    registrationUrl: 'https://my.emenu.tech/account/register',
  },
};