<lib-default-delivery-address-card
  [desginOptions]="desginOptions"
  [translations]="translations"
  [deliveryAddress]="deliveryAddress"
  [addresses]="addresses"
  [hasLoggedIn]="customerAppService?.hasLoggedIn"
  [loading]="loading"
  [showAddressForm]="showAddressForm"
  (openAddress)="openAddAddress()"
  (editAddress)="openEditAddress()"
>
<ng-template #addressCard>
  <app-address-card [viewActions]="false" [showAddressOnly]="true" [address]="deliveryAddress"></app-address-card>
</ng-template>

<ng-template #addressForm>
  <app-address-form (updateAddress)="updateAddress($event)" style="display: block" [submited]="submited"></app-address-form>
</ng-template>

</lib-default-delivery-address-card>