import { Component,ViewContainerRef, EventEmitter, Injector, Input, Output, Optional } from '@angular/core';
import { DeliveryAddressDesignOptions } from '@storefront/ng.themes/lib/themes/default/default-delivery-address-card/models';
import { AddressDto } from '@tajer/api';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AddressBookCardComponent, AddressBookCardComponentContext } from '../address-book-card/address-book-card.component';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { AddressFormComponent, AddressFormComponentContext } from '../address-form/address-form.component';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';

@Component({
  selector: 'app-delivery-address-card',
  templateUrl: './delivery-address-card.component.html',
  styleUrls: ['./delivery-address-card.component.scss'],
})
export class DeliveryAddressCardComponent extends BaseComponent {
  //#region variables
  desginOptions: DeliveryAddressDesignOptions;

  @Input() deliveryAddress: AddressDto;
  @Input() addresses: AddressDto[];
  @Input() loading: boolean;
  @Input() submited: boolean;
  @Input() wideModal: boolean
  @Input() showAddressForm: boolean;

  @Output() changeAddress = new EventEmitter<AddressDto>();
  //#end variables

  constructor(injector: Injector, 
    private addressAppService: AddressAppService,) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };
  }
  
  async openAddAddress() {
    const componentContext = <AddressFormComponentContext>{
      isModal: true,
      size: 'lg',      
    };
    
    const dialogRef = this.modal.open(
      AddressFormComponent,
      overlayConfigFactory(componentContext)
    );

      dialogRef.result.then(
      (result) => {
        this.changeAddress.emit(result);
        if (result) {
          this.addressAppService.setTemp(true);
          this.changeAddress.emit(result);
        }else{
          this.addressAppService.setTemp();
        }
      },
      () => {
        return;
      }
    );
  }

  async openEditAddress() {
    const componentContext = <AddressBookCardComponentContext>{
      isAddressBookCardModal: true,
      viewActions: false,
      addresses: this.addresses,
      loading: this.loading,
      size: 'sm',      
    };
    
    const dialogRef = this.modal.open(
      AddressBookCardComponent,
      overlayConfigFactory(componentContext)
    );

      dialogRef.result.then(
      (result) => {
        if (result) {
          this.changeAddress.emit(result);
        }
      },
      () => {
        return;
      }
    );
  }

  updateAddress(address: any) {
    this.changeAddress.emit(address);
  }
}
